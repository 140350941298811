import React, { useMemo } from 'react';
import { TiThSmall } from 'react-icons/ti';
import { IoPerson, IoFolderOpenSharp } from 'react-icons/io5';
import { HiMiniBookOpen } from 'react-icons/hi2';
import { BsStars } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import { Typography } from '@mx/ui';
import { useAppContext } from '@/common';
import { StudentEntity } from '@/domains/student/entities';
import { Avatar } from '@/components/Avatar';
import { StudentStatus } from '@/modules/student-profile/components';

type MenuItemType = {
  icon?: React.ReactNode;
  label: string;
  disabled?: boolean;
  linkTo: string;
};

type MenuItemProps = MenuItemType;

export const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps): JSX.Element => {
  const { icon, label, linkTo, disabled } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathnameList = pathname.split('/');
  const menuPath = pathnameList.length > 1 ? pathnameList[1] : pathnameList[0];

  const isActive = `/${menuPath}` === linkTo;

  const handleClick = (): void => {
    navigate(linkTo);
  };

  return (
    <button
      className={`
        flex
        items-center
        gap-2
        p-4
        border
        border-solid
        box-border
        h-10
        sm:h-14
        rounded-lg
        cursor-pointer
        disabled:cursor-not-allowed
        ${isActive ? 'bg-red-600' : 'bg-white'}
        ${isActive ? 'text-white' : 'text-gray-900'}
        ${isActive ? 'border-red-600' : 'border-gray-100'}
        hover:bg-red-500
        hover:text-white
        disabled:bg-gray-50
        disabled:text-gray-500
      `}
      type="button"
      disabled={disabled}
      onClick={handleClick}
    >
      {icon ? <div className="flex items-center">{icon}</div> : null}
      <span className="text-xs sm:text-lg font-semibold">{label}</span>
    </button>
  );
};

type MobileSidebarProps = {
  t: TFunction;
  open: boolean;
  items: React.ReactNode;
  onClose(): void;
};

const MobileSidebar: React.FC<MobileSidebarProps> = ({
  t,
  open,
  items,
  onClose,
}: MobileSidebarProps): JSX.Element => {
  return (
    <>
      {open && (
        <div
          className="
          fixed
          top-16
          h-[calc(100vh-4rem)]
          inset-0
          sm:hidden
          grow
          bg-[rgba(0,0,0,0.15)]
          z-[998]
        "
          onClick={onClose}
          aria-hidden="true"
        />
      )}
      <div
        className={`
        w-[235px]
        fixed
        top-16
        h-[calc(100vh-4rem)]
        transition-all
        duration-200
        ${open ? 'left-0' : 'left-[-100%]'}
        flex
        z-[999]
      `}
      >
        <div
          className={`
          w-full
          bg-white
          h-full
          overflow-y-scroll
          py-9
          box-border
          overflow-x-hidden
          px-4
          flex
          flex-col
          gap-3
          shadow-sm
        `}
        >
          <Typography
            fontTypo="heading-s-desktop"
            content={t('common:sidebarTitle')}
            className="text-red-700"
            weight="semibold"
          />
          {items}
        </div>
      </div>
    </>
  );
};

type DesktopSidebarProps = {
  t: TFunction;
  items: React.ReactNode;
  student: StudentEntity;
};

const DesktopSidebar: React.FC<DesktopSidebarProps> = (props: DesktopSidebarProps): JSX.Element => {
  const { items, student, t } = props;

  return (
    <div
      className={`
        !w-[286px]
        bg-inherit
        box-border
        overflow-x-hidden
        flex
        flex-col
        gap-10
        pb-4
      `}
    >
      {student && (
        <div className="flex flex-col items-center gap-2">
          <div
            className="
            w-fit
            h-fit
            rounded-full
            border-8
            border-solid
            border-white
            shadow-[0_4px_8px_0_rgba(0,0,0,0.15)]
          "
          >
            <Avatar src={student.imageUrl} shape="circle" className="w-36 h-36 shadow-none" />
          </div>
          <Typography
            fontTypo="body-xxl-desktop"
            content={student.fullName}
            weight="bold"
            className="text-center"
          />
          <StudentStatus value={student.status} t={t} />
        </div>
      )}
      <div className="w-full flex flex-col gap-4">{items}</div>
    </div>
  );
};

type SideBarProps = {
  isMobile: boolean;
  open: boolean;
  onClose(): void;
};

export const SideBar: React.FC<SideBarProps> = (props: SideBarProps): JSX.Element => {
  const { isMobile, open, onClose } = props;
  const { t, currentStudent } = useAppContext();

  const iconSize = isMobile ? 'w-4 h-4' : 'w-5 h-5';

  const menuItems: MenuItemType[] = useMemo(
    () => [
      {
        icon: <TiThSmall className={iconSize} />,
        label: t('common:homeMenuLabel'),
        linkTo: '/',
      },
      {
        icon: <IoPerson className={iconSize} />,
        label: t('common:profileDetailMenuLabel'),
        linkTo: '/profile',
      },
      {
        icon: <HiMiniBookOpen className={iconSize} />,
        label: t('common:learningProgressMenuLabel'),
        linkTo: '/learning-progress',
      },
      {
        icon: <BsStars className={iconSize} />,
        label: t('common:courseManagementMenuLabel'),
        linkTo: '/student-courses',
        disabled: true,
      },
      {
        icon: <IoFolderOpenSharp className={iconSize} />,
        label: t('common:studentProductMenuLabel'),
        linkTo: '/study-products',
        disabled: true,
      },
    ],
    [],
  );

  const renderMenus = (): JSX.Element => {
    return (
      <>
        {menuItems.map((item: MenuItemType, index: number) => (
          <MenuItem key={index} {...item} />
        ))}
      </>
    );
  };

  if (isMobile) {
    return <MobileSidebar t={t} items={renderMenus()} open={open} onClose={onClose} />;
  }

  if (!currentStudent) {
    return <></>;
  }
  return <DesktopSidebar t={t} student={currentStudent} items={renderMenus()} />;
};
