import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NavBar } from '../Navbar';
import { Footer } from '../Footer';
import { SideBar } from '../SideBar';
import { useAppContext, useBrowserDevice } from '@/common';
import { useDataLayerAction } from '@/core/context/TagContext';
import { getCurrentStudentId } from '@/common/storages';

export const MainLayout: React.FC<unknown> = (): JSX.Element => {
  const { currentStudent } = useAppContext();
  const { pathname } = useLocation();
  const { isMobile } = useBrowserDevice();
  const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const dataLayerAction = useDataLayerAction();
  const studentId = getCurrentStudentId();

  const onToggleSideBar = () => {
    setShowSidebar((pre) => !pre);
  };

  useEffect(() => {
    setShowSidebar(false);
  }, [isMobile, pathname]);

  const handleBeforeUnload = (): void => {
    dataLayerAction({
      event: 'LOGIN_SYSTEM',
      data: {
        user_id: studentId || '',
        total_time: (new Date().getTime() - currentTime) / 1000 / 60 / 60,
        student_name: currentStudent?.fullName || '',
      },
    });
  };

  useEffect(() => {
    setCurrentTime(new Date().getTime());
    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="w-full flex flex-col bg-[#F8F8F8] overflow-x-hidden">
      <NavBar isMobile={isMobile} toggleSideBar={onToggleSideBar} />
      <div className="container m-auto flex sm:grid sm:grid-cols-[286px_1fr] mt-16 sm:mt-28 gap-10 lg:px-4 box-border">
        <SideBar isMobile={isMobile} open={showSidebar} onClose={onToggleSideBar} />
        <div className="overflow-hidden w-full">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};
