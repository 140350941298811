import { useContext } from 'react';
import { App } from '@/common/context/AppContext';

export const useAppContext = () => {
  const context = useContext(App);
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};
