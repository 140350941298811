import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getAccessToken } from '../storages';
import i18n from '@/i18n';
import { config } from '@/config';
import { useStudentViewModel } from '@/modules/home/viewmodels/student';
import { StudentEntity } from '@/domains/student/entities';

export interface AppContextProps {
  t: TFunction;

  changeLanguage(lng: string): Promise<TFunction>;

  currentLanguage: string;
  currentStudent: StudentEntity | null;
  authenticated: boolean;

  setAuthenticated(value: boolean): void;
}

export const App = React.createContext<AppContextProps | undefined>(undefined);

export interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(Boolean(getAccessToken()));

  const { student, actionGetCurrentStudent } = useStudentViewModel();
  const { t } = useTranslation();
  const { language, changeLanguage } = i18n;

  useEffect(() => {
    if (authenticated) {
      actionGetCurrentStudent();
    }
  }, [authenticated]);

  const contextValue = useMemo(
    () => ({
      t,
      changeLanguage,
      currentLanguage: language || config.i18n.defaultLang,
      authenticated,
      setAuthenticated,
      currentStudent: student,
    }),
    [t, changeLanguage, language, authenticated, student],
  );

  return <App.Provider value={contextValue}>{children}</App.Provider>;
};
